import React from 'react';

const Dropdown = ({ colorOffset, expanded, children }) => {
  return (
    <div
      className={`w-full max-h-2/3 flex-col divide-y-2 overflow-y-auto lg:hidden ${
        expanded ? 'flex' : 'hidden'
      } ${
        colorOffset ? 'divide-white bg-white' : 'divide-s4tk-blue bg-s4tk-blue'
      }
      `}
    >
      {children}
    </div>
  );
};

export default Dropdown;
