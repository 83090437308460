import React from 'react';
import { Link } from 'gatsby';

const NavItem = ({ mainItem, dropdownItems }) => {
  return (
    <Link
      className="group h-full inline-block relative border-b-4 border-transparent hover:border-s4tk-blue"
      to={mainItem.path}
    >
      <div className="flex w-full h-full items-end justify-start">
        <p
          to={mainItem.path}
          className="capitalize font-baloo text-3xl text-s4tk-blue font-bold whitespace-nowrap"
        >
          {mainItem.text}
        </p>
      </div>
      {dropdownItems === undefined ? null : (
        <ul className="group bg-white absolute hidden group-hover:block mt-1 border-4 border-white rounded-b-lg shadow-xl">
          {dropdownItems.map((item, index) => (
            <li key={index}>
              <Link
                to={item.path}
                className="capitalize text-lg font-baloo text-s4tk-blue hover:text-s4tk-orange bg-white py-1 px-4 block whitespace-nowrap"
              >
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Link>
  );
};

export default NavItem;
