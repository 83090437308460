import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../img/logo2.png';

export const Footer = () => {
  return (
    <div className="w-full flex flex-col justify-around items-center bg-s4tk-green absolute bottom-0 h-48 overflow-hidden py-8 px-8 lg:px-32">
      <div className="w-full h-1/2 flex justify-center lg:justify-start items-center">
        <img alt="logo" className="h-full object-contain" src={logo} />
      </div>
      <div className="w-full lg:w-1/2 h-1/2 flex flex-row justify-around items-center">
        <a href="https://www.facebook.com/Sports4TheKids/">
          <FontAwesomeIcon
            className="text-white text-4xl"
            icon={['fab', 'facebook']}
          />
        </a>
        <a href="https://www.instagram.com/sports4tk/">
          <FontAwesomeIcon
            className="text-white text-4xl"
            icon={['fab', 'instagram']}
          />
        </a>
        <a href="https://twitter.com/Sports4theKids">
          <FontAwesomeIcon
            className="text-white text-4xl"
            icon={['fab', 'twitter']}
          />
        </a>
        <a href="https://www.youtube.com/channel/UCQa7Zbrdv3-_giYhQt0JVVA">
          <FontAwesomeIcon
            className="text-white text-4xl"
            icon={['fab', 'youtube']}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
