import React, { useState } from 'react';
import { Link } from 'gatsby';
import Dropdown from './Dropdown';
import NavItem from './NavItem';
import MobileNavItem from './MobileNavItem';

import logo from '../../img/logo.jpg';

const NavContainer = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <nav className="fixed top-0 z-50 flex flex-col w-full bg-white">
      <div className="flex flex-row items-center justify-center w-full h-24 md:h-32">
        <div className="flex flex-row items-center justify-between w-full h-full px-6 sm:px-12 lg:justify-around lg:px-12">
          <Link
            to="/"
            className="flex flex-row items-center justify-start w-2/4 h-full py-2 lg:w-1/5"
          >
            <img alt="logo" className="object-contain h-full" src={logo} />
          </Link>
          <div className="flex items-center justify-end w-1/4 pr-6 lg:hidden">
            <button
              onClick={() => setExpanded(!expanded)}
              className="text-s4tk-blue"
            >
              <svg
                className="fill-current h-7 w-7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <div className="flex-row items-end justify-around hidden w-3/4 h-full lg:w-3/5 lg:flex">
            <NavItem
              mainItem={{ path: '/about', text: 'About Us' }}
              dropdownItems={[
                { path: '/about', text: 'Our Mission' },
                { path: '/about/our-team', text: 'Our Team' },
                { path: '/programs/spotlights', text: 'Our Athletes' },
                { path: '/about/program-providers', text: 'Program Providers' },
                { path: '/about/sponsors', text: 'Sponsors' },
                { path: '/about/donations', text: 'Donations' },
                { path: '/about/testimonials', text: 'Testimonials' },
                { path: '/about/s4tk-club', text: 'S4TK Club' },
                { path: '/about/volunteer', text: 'Volunteer' },
                { path: '/about/s4tk-internship', text: 'S4TK Internship' },
                { path: '/about/contact', text: 'Contact' }
              ]}
            />
            <NavItem
              mainItem={{ path: '/scholarships', text: 'Scholarships' }}
              dropdownItems={[
                { path: '/scholarships', text: 'Seminole County' },
                { path: '/scholarships', text: 'Orange County' },
                { path: '/scholarships', text: 'Lake County' },
                { path: '/scholarships', text: 'Volusia County' },
                {
                  path: '/scholarships#nina-karlinsky',
                  text: 'Nina Karlinsky Soccer'
                },
                {
                  path: '/scholarships#raymond-caraballo',
                  text: 'Raymond Caraballo Football'
                },
                {
                  path: '/scholarships#sam-shaw',
                  text: 'Sam Shaw Sports'
                },
                {
                  path: '/scholarships#anna-kathryne',
                  text: 'Anna Proctor Volleyball'
                }
              ]}
            />
            <NavItem
              mainItem={{ path: '/programs/spotlights', text: 'Programs' }}
              dropdownItems={[
                { path: '/programs/spotlights', text: 'Spotlight Athletes' },
                { path: '/programs/ambassadors', text: 'S4TK Ambassadors' },
                {
                  path: '/programs/g4tk',
                  text: 'Goals 4 The Kids'
                },
                { path: '/programs/collection-drive', text: 'Collection Drive' }
              ]}
            />
            <NavItem
              mainItem={{
                path: '/events/golf-tournament',
                text: 'Events'
              }}
              dropdownItems={[
                { path: '/events/golf-tournament', text: 'Golf Tournament' },
                { path: '/events/b4tk', text: 'Balls 4 The Kids' },
                { path: '/events/w4tk', text: 'Wheels 4 The Kids' },
                { path: '/events/gear-up', text: 'Gear Up' },
                {
                  path: '/events/fitness-fest',
                  text: 'Family Fitness Fest'
                },
                { path: '/events/open-house', text: 'Open House' }
              ]}
            />
          </div>
        </div>
      </div>
      <Dropdown expanded={expanded}>
        <MobileNavItem
          setParentExpanded={setExpanded}
          isMain
          mainItem={{ path: '/about', text: 'About Us' }}
          dropdownItems={[
            { path: '/about', text: 'Our Mission' },
            { path: '/about/our-team', text: 'Our Team' },
            { path: '/programs/spotlights', text: 'Our Athletes' },
            { path: '/about/program-providers', text: 'Program Providers' },
            { path: '/about/sponsors', text: 'Sponsors' },
            { path: '/about/donations', text: 'Donations' },
            { path: '/about/testimonials', text: 'Testimonials' },
            { path: '/about/s4tk-club', text: 'S4TK Club' },
            { path: '/about/volunteer', text: 'Volunteer' },
            { path: '/about/s4tk-internship', text: 'S4TK Internship' },
            { path: '/about/contact', text: 'Contact' }
          ]}
        />
        <MobileNavItem
          setParentExpanded={setExpanded}
          isMain
          mainItem={{ path: '/scholarships', text: 'Scholarships' }}
          dropdownItems={[
            { path: '/scholarships', text: 'Seminole County' },
            { path: '/scholarships', text: 'Orange County' },
            { path: '/scholarships', text: 'Lake County' },
            { path: '/scholarships', text: 'Volusia County' },
            {
              path: '/scholarships#nina-karlinsky',
              text: 'Nina Karlinsky Soccer'
            },
            {
              path: '/scholarships#raymond-caraballo',
              text: 'Raymond Caraballo Football'
            },
            {
              path: '/scholarships#sam-shaw',
              text: 'Sam Shaw Sports'
            },
            {
              path: '/scholarships#anna-kathryne',
              text: 'Anna Proctor Volleyball'
            }
          ]}
        />
        <MobileNavItem
          setParentExpanded={setExpanded}
          isMain
          mainItem={{ path: '/programs/spotlights', text: 'Programs' }}
          dropdownItems={[
            { path: '/programs/spotlights', text: 'Spotlight Athletes' },
            { path: '/programs/ambassadors', text: 'S4TK Ambassadors' },
            {
              path: '/programs/g4tk',
              text: 'Goals 4 The Kids'
            },
            { path: '/programs/collection-drive', text: 'Collection Drive' }
          ]}
        />
        <MobileNavItem
          setParentExpanded={setExpanded}
          isMain
          mainItem={{
            path: '/events/golf-tournament',
            text: 'Events'
          }}
          dropdownItems={[
            { path: '/events/golf-tournament', text: 'Golf Tournament' },
            { path: '/events/b4tk', text: 'Balls 4 The Kids' },
            { path: '/events/w4tk', text: 'Wheels 4 The Kids' },
            { path: '/events/gear-up', text: 'Gear Up' },
            {
              path: '/events/fitness-fest',
              text: 'Family Fitness Fest'
            },
            { path: '/events/open-house', text: 'Open House' }
          ]}
        />
      </Dropdown>
    </nav>
  );
};

export default NavContainer;
