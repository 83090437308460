import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import NavContainer from './Nav/NavContainer';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserFriends,
  faBook,
  faAward,
  faUsers,
  faCertificate,
  faHandHoldingHeart,
  faRecycle,
  faSchool,
  faRunning,
  faDumbbell,
  faEdit,
  faUser,
  faBicycle,
  faWeight,
  faAt,
  faTasks,
  faHeartbeat,
  faChalkboardTeacher,
  faUserEdit,
  faBaseballBall,
  faFootballBall,
  faGifts,
  faGift,
  faStore,
  faMedal,
  faGolfBall,
  faHandsHelping,
  faFutbol
} from '@fortawesome/free-solid-svg-icons';
import { faHeart, faGrinStars } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(
  faUserFriends,
  faUsers,
  faUser,
  faHeartbeat,
  faGift,
  faGifts,
  faStore,
  faEdit,
  faBicycle,
  faAt,
  faWeight,
  faTasks,
  faCertificate,
  faHandHoldingHeart,
  faChalkboardTeacher,
  faDumbbell,
  faUserEdit,
  faBook,
  faGrinStars,
  faRunning,
  faRecycle,
  faSchool,
  faAward,
  faHeart,
  faFutbol,
  faMedal,
  faBaseballBall,
  faFootballBall,
  faHandsHelping,
  faGolfBall,
  fab
);

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div className="min-h-screen relative">
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#005ed2"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <NavContainer />
      <div className="mt-24 md:mt-32 pb-48">{children}</div>
      <Footer />
    </div>
  );
};

export default TemplateWrapper;
