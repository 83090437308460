import React, { useState } from 'react';
import { navigate } from 'gatsby';
import Dropdown from './Dropdown';

const MobileNavItem = ({
  colorOffset,
  mainItem,
  dropdownItems,
  setParentExpanded,
  isMain
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setParentExpanded(false);
    navigate(mainItem.path);
  };

  return (
    <div className="w-full flex flex-col justify-center flex-shrink-0">
      <div
        className={`w-full flex px-4 justify-between py-4 ${
          colorOffset ? 'bg-s4tk-blue' : 'bg-white'
        }`}
      >
        {/* <Link
          className={`uppercase font-baloo font-semibold ${
            colorOffset ? 'text-white' : 'text-s4tk-blue'
          }`}
          to={mainItem.path}
        >
          {mainItem.text}
        </Link> */}
        {isMain ? (
          <button
            className={`uppercase font-baloo font-semibold ${
              colorOffset ? 'text-white' : 'text-s4tk-blue'
            }`}
            onClick={() => setExpanded(!expanded)}
          >
            {mainItem.text}
          </button>
        ) : (
          <button
            className={`uppercase font-baloo font-semibold ${
              colorOffset ? 'text-white' : 'text-s4tk-blue'
            }`}
            onClick={handleClick}
          >
            {mainItem.text}
          </button>
        )}
        {dropdownItems === undefined ? null : (
          <>
            <button
              onClick={() => setExpanded(!expanded)}
              className="text-s4tk-orange"
            >
              <svg
                className="fill-current h-5 w-5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {expanded ? (
                  <>
                    <title>Less</title>
                    <path d="M0 10h24v4h-24z" />
                  </>
                ) : (
                  <>
                    <title>More</title>
                    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
                  </>
                )}
              </svg>
            </button>
          </>
        )}
      </div>
      {dropdownItems === undefined ? null : (
        <Dropdown colorOffset expanded={expanded}>
          {dropdownItems.map((item, index) => (
            <MobileNavItem
              key={index}
              colorOffset
              mainItem={item}
              setParentExpanded={setParentExpanded}
            />
          ))}
        </Dropdown>
      )}
    </div>
  );
};

MobileNavItem.defaultProps = {
  colorOffset: false
};

export default MobileNavItem;
